div[data-reach-dialog-overlay] {
  z-index: 1;
}

div[data-reach-slider-track] {
  background-color: #333;
}

div[data-reach-slider-range] {
  background-color: limegreen;
}

div[data-reach-slider-handle] {
  background-color: #ccc;
  box-shadow: 0 0.16em 0 0 #555;
  border-radius: 0.3em;
  width: 1.4em;

  &:focus-visible {
    outline: 0.2em solid limegreen;
  }

  &:hover {
    cursor: grab;
  }

  &[aria-orientation="horizontal"] {
    top: calc(50% - 0.1em);
  }
}

div[data-reach-dialog-content] {
  width: calc(100vw - 2em);
  margin: 1em auto;
  background: white;
  padding: 2rem;
  outline: none;
  max-widtH: 40em;
}

.controls__modifiers {
  label {
    align-items: center;
    display: flex;
  }

  label:focus-within {
    outline: 1px dotted limegreen;
  }
}

span[data-reach-custom-checkbox-container] {
  border: 0.2em solid black;
  height: 0.8em;
  margin-left: 0.3em;
  width: 0.8em;

  &:focus-within {
    box-shadow: none;
  }

  &[data-state="checked"] {
    background-color: limegreen;
  }
}
